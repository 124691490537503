import './Home.css'
import Accordion from '../../Components/Accordion/Accordion'
import Brand from '../../Components/Brand/Brand'
import Header from '../../Components/Header/Header'
import HowWork from '../../Components/HowWork/HowWork'
import { Link } from 'react-router-dom'

const Home = () => {
  const header = {
    title: 'FAQs'
  }
  const faqs = [
    {
        question: "How much does it cost?",
        answer: "Chefsknock is free for small businesses! You can buy optional upgrades if you want to support us. The free version of Chefsknock already gives you more than any other platform, but we help you hire chefs/cooks for your business even further with our paid plans."
    },
    {
        question: "How to hire a chef?",
        answer: "Click on the Hire Chefs button to see ready-to-book Menus and their price quotes in your cuisine of choice. Menus are also available on Chef profile pages and click on the Book Now button to hire a chef."
    },
    {
        question: "What equipment do I need to provide?",
        answer: "Our chefs usually use the equipment (pots, pans, oven, stone etc), and serve ware (glasses, plates, serving platters, etc) in the customers homes. If you are low on equipment or need the chef to provide this, please add this into your request (this will cost extra). Once booked in, you can directly message or call your chef to confirm on the exact equipment required. In case of BBQ equipment, we suggest hiring from AllensHire as the best option as they deliver within 48 hours and offer both charcoal and gas BBQs."
    },
    {
        question: "Where do my bookings go?",
        answer: "We've built a booking management system for you. Simply log in to your partner dashboard and you'll be able to manage your bookings, see your earnings, add products, and more!"
    },
    {
        question: "How long does it take to get set up?",
        answer: "Literally less than 5 minutes! You can get started right away."
    },
    {
        question: "What countries do you support?",
        answer: "Anyone can use Chefsknock from anywhere in the world!"
    },
    {
        question: "Where can I get in touch for corporate or custom solutions?",
        answer: "If you have special requirements or want custom solutions with our platform, get in touch with us here.\n\nFor general inquiries about our product, send us an email here."
    }
  ];

  return (
    <div>
        <Header />
        <div className="content-section second-section">
            <div className="container">
                <div className="title-wrap-centre">
                    <h2 className="heading heading-intro">Why Chefsknock?</h2>
                </div>
                <p className="paragraph-home">Chef's Knock has a large database of cooks to choose from and offers high-quality options for clients. We listen to your needs and provide clear information about the hiring process. Our team works quickly to find the right match for you. If you need help or a replacement, our support team is always available to assist you.<br /></p>
                <div className="demo-container">
                    <div className="button-wrapper heroContentWrap">
                        <Link event-property-location="Mission Statement" event-tracking="Element Clicked"
                            event-property-id="11" event-property-action="Go to Demo Shop" to="/login" 
                            className="button demo w-button">Hire Chefs
                        </Link>
                    </div>
                </div>
            </div>
        </div>
 
        <Brand />
        <div className="content-section features-first">
            <div className="container features-container">
            <div className="w-layout-grid content-grid">
                <div className="content-block">
                <h2 className="features-title">Wide Network of Culinary Professionals.</h2>
                <p className="paragraph-home features-description">Connect with a diverse community of experienced cooks and chefs, ensuring access to a variety of talented options for your culinary needs.</p>
                </div>
                <div className="image-block">
                    <img
                    src="assets/images/chefs_list_design.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 88vw, 872.9948120117188px"
                    srcset="assets/images/chefs_list_design_500.png 500w, assets/images/chefs_list_design_800.png 800w, assets/images/chefs_list_design_1280.png 1280w"
                    alt="" className="image-2" /></div>
            </div>
            </div>
        </div>
        <div className="content-section">
            <div className="container">
            <div className="w-layout-grid content-grid features-middle">
                <div className="image-block">
                    <img
                    src="assets/images/chef_details.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 94vw, 850.0000610351562px"
                    srcset="assets/images/chef_details_500.png 500w, assets/images/chef_details_800.png 800w, assets/images/chef_details_1080.png 1080w, assets/images/chef_details_1280.png 1280w"
                    alt="" className="image-13" /></div>
                <div className="content-block">
                <h2 className="features-title">Detailed Chef Profiles.</h2>
                <p className="paragraph-home features-description">Explore comprehensive profiles of cooks and chefs, including their culinary journey, qualifications, expertise, and past work experience, to make an informed hiring decision.</p>
                </div>
            </div>
            </div>
        </div>

        <div className="content-section features-first">
            <div className="container features-container">
            <div className="w-layout-grid content-grid">
                <div className="content-block">
                <h2 className="features-title">Easy Search and Filters.</h2>
                <p className="paragraph-home features-description">Use our simple search function and filters like cuisine type, location, availability, and price range to quickly find the perfect cook or chef based on your specific requirements.</p>
                </div>
                <div className="image-block">
                    <img
                    src="assets/images/chef_filters.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 88vw, 872.9948120117188px"
                    srcset="assets/images/chef_filters_500.png 500w, assets/images/chef_filters_800.png 800w, assets/images/chef_filters_1280.png 1280w"
                    alt="" className="image-2" /></div>
            </div>
            </div>
        </div>
        <div className="content-section">
            <div className="container">
            <div className="w-layout-grid content-grid features-middle">
                <div className="image-block">
                    <img
                    src="assets/images/chef_review.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 94vw, 850.0000610351562px"
                    srcset="assets/images/chef_review_500.png 500w, assets/images/chef_review_800.png 800w, assets/images/chef_review_1080.png 1080w, assets/images/chef_review_1280.png 1280w"
                    alt="" className="image-13" /></div>
                <div className="content-block">
                <h2 className="features-title">Ratings and Reviews.</h2>
                <p className="paragraph-home features-description">Make confident hiring decisions by referring to ratings and reviews from previous clients, gaining insights into a cook or chef's professionalism, culinary skills, and ability to meet client expectations.</p>
                </div>
            </div>
            </div>
        </div>

        <Accordion header={header} data={faqs} />
        <HowWork />
    </div>
  )
}

export default Home
