import React from 'react'
import Accordion from '../../Components/Accordion/Accordion';

const Privacy = () => {
    const header = {
        title: 'Privacy Policy',
        subTitle: "How we handle your data"
    }
    const data = [
        {
          question: "Introduction",
          answer: "Chefsknock is an e-commerce platform for businesses to start an online shop easily. At Chefsknock, your privacy is important to us, and we want you to feel confident that your personal information is secure when using our products and our platform. It is Chefsknock's policy to respect your privacy regarding any information we may collect while operating our website."
        },
        {
          question: "Website Visitors",
          answer: "Like most website operators, Chefsknock collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Chefsknock's purpose in collecting non-personally identifying information is to better understand how Chefsknock's visitors use its website. From time to time, Chefsknock may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website. Chefsknock also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged-in users and for users making payments on Chefsknock. Chefsknock only discloses logged-in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below, except that payee IP addresses and email addresses are visible and disclosed to the administrators of Chefsknock and is handled by payment processors at the time of processing the payments."
        },
        {
          question: "Payments",
          answer: "To make a payment to a creator on Chefsknock, you have to provide our payment processor (Stripe) with your payment information. They provide us with a token that represents your account, your card's expiration date, card type, and the last four digits of your credit card. If you provide them with a name and email address, then they also provide us with that information."
        },
        {
          question: "Gathering of Personally-Identifying Information",
          answer: "Certain visitors to Chefsknock's websites choose to interact with Chefsknock in ways that require Chefsknock to gather personally-identifying information. The amount and type of information that Chefsknock gathers depend on the nature of the interaction. For example, we ask sellers who sign up on Chefsknock to provide an email address and name. Those who engage in transactions with Chefsknock are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Chefsknock collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Chefsknock. Chefsknock does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities."
        },
        {
          question: "Protection of Certain Personally-Identifying Information",
          answer: "Chefsknock discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors, and affiliated organizations that (i) need to know that information to process it on Chefsknock's behalf or to provide services available at Chefsknock's websites and (ii) that have agreed not to disclose it to others. Some of those employees, contractors, and affiliated organizations may be located outside of your home country; by using Chefsknock's websites, you consent to the transfer of such information to them. Chefsknock will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors, and affiliated organizations, as described above, Chefsknock discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order, or another governmental request, or when Chefsknock believes in good faith that disclosure is reasonably necessary to protect the property or rights of Chefsknock, third parties, or the public at large. If you are a registered user of Chefsknock and have supplied your email address, Chefsknock may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Chefsknock and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Chefsknock takes all measures reasonably necessary to protect against the unauthorized access, use, alteration, or destruction of potentially personally-identifying and personally-identifying information."
        },
        {
          question: "Cookies",
          answer: "A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Chefsknock uses cookies to help Chefsknock identify and track visitors, their usage of Chefsknock website, and their website access preferences. Chefsknock visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Chefsknock's websites, with the drawback that certain features of Chefsknock's websites may not function properly without the aid of cookies."
        },
        {
          question: "Business Transfers",
          answer: "If Chefsknock, or substantially all of its assets, were acquired, or in the unlikely event that Chefsknock goes out of business or enters bankruptcy, user information would be one of the assets that are transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of Chefsknock may continue to use your personal information as set forth in this policy."
        },
        {
          question: "Privacy Policy Changes",
          answer: "Although most changes are likely to be minor, Chefsknock may change its Privacy Policy from time to time, and at Chefsknock's sole discretion. Chefsknock encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change."
        }
    ];

    return (
        <Accordion header={header} data={data} />
    )
}

export default Privacy
