import React from 'react';
import { Link } from 'react-router-dom';
import { getBaseURL } from '../../Utils/PathUtils';

const Footer = () => {
  const BASE_URL = getBaseURL();

  return (
    <div className="footer">
      <div className="container">
        <div className="w-layout-grid footer-grid">
          <div
            id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da5a-dcc6da57"
            className="footer-column"
          >
            <div className='flex-active'>
              <img
                src="assets/images/white_chefsknock_icon.png"
                height="40"
                alt=""
                className="footer-logo"
                style={{height: "50px", width: "50px"}}
              /> <span className='nav-logo-name' style={{fontSize: "29px"}}>Chefsknock</span>
            </div>
            <p className="paragraph-home paragraph-footer">
              Find verified and experienced chefs easily.
            </p>
          </div>
          <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da5e-dcc6da57" class="footer-column">
          <div class="title">Product</div>
          <Link to="/" class="footer-link">Home</Link>
          <Link to="/why-us" class="footer-link">Why Us</Link>
          {/* <Link to="/pricing" class="footer-link">Pricing</Link> */}
          <Link to="mailto:contact@chefsknock.com" class="footer-link">Contact</Link>
          {/* <Link to="https://www.chefsknock.com/partners" target="_blank" class="footer-link">Deals</Link> */}
        </div>
        <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da69-dcc6da57" class="footer-column">
          <div class="title">Resources</div>
          {/* <Link to="https://www.chefsknock.com/resources" class="footer-link">Blog</Link> */}
          <Link to="https://www.instagram.com/chefsknock/" target="_blank" class="footer-link">Instagram</Link>
          <Link to="https://www.facebook.com/chefsknock/" target="_blank" class="footer-link">Facebook</Link>
          <Link to="https://twitter.com/chefknock" target="_blank" class="footer-link">Twitter</Link>
          {/* <Link to="https://support.cococart.co/" target="_blank" class="footer-link">Help Center</Link> */}
        </div>
        {/* <div id="w-node-bf0e88b5-5151-a0f5-5a5f-683bc15c39ba-dcc6da57" class="footer-column">
          <div class="title">Sell Anything</div>
          <Link to="http://www.chefsknock.com/sell-crafts-online-easy" target="_blank" class="footer-link">Crafts</Link>
          <Link to="http://www.chefsknock.com/sell-merch-online-free-website" target="_blank" class="footer-link">Merchandise</Link>
          <Link to="http://www.chefsknock.com/sell-food-online-easy" class="footer-link">Food and Drink</Link>
        </div> */}
        <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da72-dcc6da57" class="footer-column">
          <div class="title">Company</div>
          <Link to={BASE_URL + "/chefs/register"} target="_blank" class="footer-link">We&#x27;re Hiring</Link>
          <Link to="/terms" class="footer-link">Terms</Link>
          <Link to="/privacy" class="footer-link">Privacy</Link>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
